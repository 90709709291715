import React from "react"

import SEO from "../components/seo"
import { RootFront, Container } from "../components";
const NotFoundPage = () => (
  <RootFront>
    <Container>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </RootFront>
)

export default NotFoundPage
